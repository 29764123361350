import React, { useRef, useState } from "react";
import { Button, Card, CardActions, CardContent, CardMedia, FormControl, InputLabel, MenuItem, Select, Skeleton, Typography, SelectChangeEvent, styled, IconButtonProps, IconButton, Transitions, TextField } from "@mui/material";
import { MUIStyledCommonProps } from "@mui/system";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LeadItem } from "../lead-item.interface";
import { LeadProcessingStatus } from "../lead-processing-status.enum";
import { useDebounce, useUpdateEffect } from "react-use";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: ExpandMoreProps & MUIStyledCommonProps) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: (theme?.transitions as Transitions).create("transform", {
    duration: (theme?.transitions as Transitions).duration.shortest
  })
}));

// let throttleTimer: number = 0;

export interface LeadCardProps {
  readonly lead: LeadItem;
  onVisit: () => void;
  onProcessingStatusChange: (event: SelectChangeEvent<LeadProcessingStatus>, child: React.ReactNode) => void;
  onNoteChange?: (value: string) => void;
  onIgnore: () => void;
}

export const LeadCard = (props: LeadCardProps) => {

  const lead: LeadItem = props.lead;

  const detailEl: React.RefObject<HTMLSpanElement> = useRef<HTMLSpanElement>(null);
  const [hasMore, setHasMore] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [note, setNote] = useState(lead.note || "");
  const [debouncedNote, setDebouncedNote] = useState(note);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useUpdateEffect(() => {
    if (detailEl.current) {
      setHasMore(detailEl.current.clientHeight < detailEl.current.scrollHeight);
    }
  }, [detailEl.current]);

  useDebounce(() => {
    setDebouncedNote(note);
  }, 750, [note]);

  useUpdateEffect(() => {
    props.onNoteChange && props.onNoteChange(debouncedNote);
  }, [debouncedNote]);

  const onNoteChange = (event: React.SyntheticEvent) => {
    const value: string = (event.target as HTMLTextAreaElement).value || "";
    setNote(value);
  }

  return (
    <Card sx={{
      height: "100%",
      display: "flex",
      flexDirection: "column"
    }}>
      <CardMedia
        component="img"
        alt={ lead.title }
        height="140"
        image={ lead.coverImage }
      />
      <CardContent sx={{ flex: 1 }}>
        <Typography gutterBottom variant="h6" component="div" align="left">{ lead.title }</Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          align="left"
          gutterBottom
          mb={ 2 }
          sx={
            expanded
            ?
            {}
            :
            {
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
              textOverflow: "ellipsis",
              WebkitLineClamp: 4
            }
          }
          dangerouslySetInnerHTML={{ __html: lead.detail?.replace(/^[\s\n]*/g, "")?.replace(/[\s\n]*$/g, "")?.replaceAll(/\n/g, "<br/>") || "" }}
          ref={ detailEl }
        />
        {
          hasMore && (
            <ExpandMore
              expand={ expanded }
              onClick={ handleExpandClick }
              aria-expanded={ expanded }
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )
        }
        <TextField
          id="note-label"
          label="Note"
          name="note"
          multiline
          fullWidth
          value={ note }
          onChange={ onNoteChange }
        />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={ props.onVisit }>Visit</Button>
        <FormControl sx={{ m: 1 }} size="small">
          <InputLabel id="processing-select-label">Processing</InputLabel>
          <Select
            labelId="processing-select-label"
            label="Processing"
            name="processingStatus"
            value={ lead.processingStatus }
            onChange={ props.onProcessingStatusChange }
          >
            <MenuItem value={ LeadProcessingStatus.NONE }><em>None</em></MenuItem>
            <MenuItem value={ LeadProcessingStatus.IN_CONTACT }>In contact</MenuItem>
            <MenuItem value={ LeadProcessingStatus.FOLLOW_UP }>Follow up</MenuItem>
            <MenuItem value={ LeadProcessingStatus.QUALIFIED }>Qualified</MenuItem>
          </Select>
        </FormControl>
        <Button sx={{ marginLeft: "auto !important" }} size="small" onClick={ props.onIgnore }>Ignore</Button>
      </CardActions>
    </Card>
  );
};

export const LeadCardSkeleton = () => {
  return (
    <Card>
      <Skeleton sx={{ height: 140 }} animation="wave" variant="rectangular" />
      <CardContent>
        <Skeleton animation="wave" height={20} style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={20} width="30%" style={{ marginBottom: 6 }} />
        <Skeleton animation="wave" height={10} style={{ marginBottom: 3 }} />
        <Skeleton animation="wave" height={10} style={{ marginBottom: 3 }} />
        <Skeleton animation="wave" height={10} style={{ marginBottom: 3 }} />
      </CardContent>
      <CardActions>
        <Button size="small">Visit</Button>
        <FormControl sx={{ m: 1 }} size="small">
          <InputLabel id="processing-select-label">Processing</InputLabel>
          <Select
            labelId="processing-select-label"
            label="Processing"
            name="processingStatus"
            defaultValue={ LeadProcessingStatus.NONE }
          >
            <MenuItem value={ LeadProcessingStatus.NONE }><em>None</em></MenuItem>
            <MenuItem value={ LeadProcessingStatus.IN_CONTACT }>In contact</MenuItem>
            <MenuItem value={ LeadProcessingStatus.FOLLOW_UP }>Follow up</MenuItem>
            <MenuItem value={ LeadProcessingStatus.QUALIFIED }>Qualified</MenuItem>
          </Select>
        </FormControl>
        <Button sx={{ marginLeft: "auto !important" }} size="small">Ignore</Button>
      </CardActions>
    </Card>
  );
}