import { Box, Tooltip } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import "./FlyoutMenu.less";

export default function FlyoutMenu ({ children }: PropsWithChildren<{ children: JSX.Element[] }>): JSX.Element {

  const [open, setOpen] = useState(false);

  const flyoutBtnEl: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  const flyoutEl: React.RefObject<HTMLUListElement> = React.createRef<HTMLUListElement>();

  const handleFlyoutBtnClick = () => {
    flyoutBtnEl.current?.classList.toggle("btn-rotate");
    flyoutEl.current?.querySelectorAll(".flyout-menu-item-box.clicked").forEach((el: Element) => el.classList.remove("clicked"))
    flyoutEl.current?.classList.remove("flyout-init", "fade");
    flyoutEl.current?.classList.toggle("expand");
    if (!flyoutEl.current?.classList.contains("expand")) {
      setOpen(false);
    }
  }

  const handleMenuItemClick = (event: React.MouseEvent<HTMLDivElement>) => {
    flyoutBtnEl.current?.classList.toggle("btn-rotate");
    flyoutEl.current?.classList.remove("expand");
    flyoutEl.current?.classList.add("fade");
    event.currentTarget.classList.add("clicked");
    if (!flyoutEl.current?.classList.contains("expand")) {
      setOpen(false);
    }
  }

  const handleMenuItemAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    setOpen(flyoutEl.current?.classList.contains("expand") || false);
  }

  return (
    <Box sx={{
      position: "fixed",
      left: 20,
      bottom: 20
    }}>
      <div className="flyout-wrap">
        <div className="flyout-btn" ref={ flyoutBtnEl } title="Toggle" onClick={ handleFlyoutBtnClick }>
          <span>Flyout Menu Toggle</span>
        </div>
        <ul className="flyout flyout-init" ref={ flyoutEl }>
          {
            ([] as JSX.Element[]).concat(children).map<JSX.Element>((item: JSX.Element, index: number) => (
              <li key={ `flyout-menu-item-${ index }` }>
                <Tooltip
                  title={ item.props.title }
                  open={ open }
                  placement="right"
                  arrow={ true }
                  PopperProps={{
                    sx: {
                      "&[data-popper-placement*=\"right\"] > .MuiTooltip-tooltipArrow": {
                        marginLeft: "6px !important"
                      }
                    },
                    transition: true
                  }}
                >
                  <div className="flyout-menu-item-box" onClick={ handleMenuItemClick } onAnimationEnd={ handleMenuItemAnimationEnd }>
                    { item }
                  </div>
                </Tooltip>
              </li>
            ))
          }
        </ul>
      </div>
    </Box>
  );
};