// import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
// import logo from "./logo.svg";
import "./App.css";
// import { LeadList } from "./lead/LeadList";
import { Container } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import FlyoutMenu from "./components/FlyoutMenu";
import { LeadProcessingStatus } from "./lead-processing-status.enum";
// import { LeadListContainer } from "./lead/LeadListContainer";
// import AuthNavigate from "./AuthNavigate";

// const AuthContext = createContext(undefined);

// export const AuthProvider = ({ children }) => {
//   const [user, setUser]: [{}, SetLocalStorageKeyValueFn<{}>] = useLocalStorage("user", {});
//   const navigate = useNavigate();

//   // call this function when you want to authenticate the user
//   // const login = async (data) => {
//   //   setUser(data);
//   //   navigate("/profile");
//   // };

//   // call this function to sign out logged in user
//   // const logout = () => {
//   //   setUser(null);
//   //   navigate("/", { replace: true });
//   // };

//   const value: { user: {} } = useMemo<{ user: {} }>(
//     () => ({
//       user
//       // ,
//       // login,
//       // logout
//     }),
//     [user]
//   );
//   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
// };

function App() {

  return (
    <Container className="App">
      <h1>Welcome to Leads Center!</h1>
      <Outlet />
      <FlyoutMenu>
        <Link className="flyout-menu-item" title="None" to={ `/leads/${ LeadProcessingStatus.NONE }` }>
          <PlayArrowIcon sx={{
            width: "0.9em",
            height: "0.9em",
            fill: "#FFF"
          }}/>
        </Link>
        <Link className="flyout-menu-item" title="In contact" to={ `/leads/${ LeadProcessingStatus.IN_CONTACT }` }>
          <PhoneInTalkIcon sx={{
            width: "0.8em",
            height: "0.8em",
            fill: "#FFF"
          }}/>
        </Link>
        <Link className="flyout-menu-item" title="Follow up" to={ `/leads/${ LeadProcessingStatus.FOLLOW_UP }` }>
          <SpatialTrackingIcon sx={{
            width: "0.8em",
            height: "0.8em",
            fill: "#FFF"
          }}/>
        </Link>
        <Link className="flyout-menu-item" title="Qualified" to={ `/leads/${ LeadProcessingStatus.QUALIFIED }` }>
          <HowToRegIcon sx={{
            fill: "#FFF"
          }}/>
        </Link>
      </FlyoutMenu>
    </Container>
  );
}

// function Home() {
//   return (
//     <>
//       <main>
//         <h2>Welcome to the homepage!</h2>
//         <p>You can do this, I believe in you.</p>
//       </main>
//       <nav>
//         <Link to="/about">About</Link>
//       </nav>
//     </>
//   );
// }

// function About() {
//   return (
//     <>
//       <main>
//         <h2>Who are we?</h2>
//         <p>
//           That feels like an existential question, don"t you
//           think?
//         </p>
//       </main>
//       <nav>
//         <Link to="/">Home</Link>
//       </nav>
//     </>
//   );
// }

export default App;
