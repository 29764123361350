import { useEffect, useState, PropsWithChildren } from "react";
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import { SelectChangeEvent, styled } from "@mui/material";
import * as NProgress from "nprogress";
import InfiniteScroll, { Props } from "react-infinite-scroll-component";
import { LeadItem } from "../lead-item.interface";
import { ItemsWithPagination } from "../pagination.interface";
import { IResponse } from "../response.interface";
import { LeadProcessingStatus } from "../lead-processing-status.enum";
import { LeadCard, LeadCardSkeleton } from "./LeadCard";
import { useParams } from "react-router-dom";
import { useUpdateEffect } from "react-use";

const StyledInfiniteScroll = styled(InfiniteScroll)<Props>(({ theme }) => ({
  overflow: "inherit !important"
}));

let nprogressTimer: number = 0;

export interface LeadListProps {
  readonly processingStatus?: LeadProcessingStatus;
}

export const LeadList = (props: PropsWithChildren<LeadListProps>) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [leads, setLeads] = useState<LeadItem[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const { processingStatus } = useParams();

  console.log("popoi");

  useEffect(() => {
    if (loading) {
      NProgress.start();
      nprogressTimer = window.setTimeout(() => {
        NProgress.set(0.8);
      }, 200);
    } else {
      clearTimeout(nprogressTimer);
      nprogressTimer = 0;
      NProgress.done();
    }
  }, [loading]);

  useEffect(() => {
    fetchLeads();
  }, [page]);

  useUpdateEffect(() => {
    if (page > 1) {
      setPage(1);
    } else {
      fetchLeads();
    }
  }, [processingStatus]);

  const fetchLeads = async () => {
    if (page === 1) {
      window.scrollTo(0, 0);
    }
    setLoading(true);
    const { data }: { data: IResponse<ItemsWithPagination<LeadItem>> } = await axios.get(`/lead/list/${ processingStatus || "" }?page=${ page }&size=${ pageSize }`);
    setLeads((page === 1 ? [] : leads).concat(data.data?.items || []));
    setTotal(data.data?.pagination.total || 0);
    setPageSize(data.data?.pagination.size || pageSize);
    setPage(data.data?.pagination.page || page);
    setLoading(false);
  };

  const redirectTarget = (url: string) => {
    window.open(url, "_blank");
  };

  const handleProcessingStatusChange = (lead: LeadItem) => {
    return async (event: SelectChangeEvent<LeadProcessingStatus>) => {
      setLoading(true);
      lead.processingStatus = event.target.value as LeadProcessingStatus;
      await axios.put("/lead", lead);
      setLoading(false);
    };
  };

  const handleNoteChange = (lead: LeadItem) => {
    return async (value: string) => {
      setLoading(true);
      value = value.replace(/^\n*/, "").replace(/\n*$/, "").trim();
      await axios.put("/lead", {
        ...lead,
        note: value
      });
      lead.note = value;
      setLoading(false);
    };
  };

  const ignoreLead = (leadId: number) => {
    return async () => {
      setLoading(true);
      await axios.put(`/lead/${ leadId }/ignore`);
      setLeads(leads.filter((lead: LeadItem) => lead.id !== leadId));
      setLoading(false);
    }
  };

  const hasMore = () => total > page * pageSize ;

  console.log("leads: ", [...leads]);

  return (
    <StyledInfiniteScroll
      dataLength={ leads.length }
      hasMore={ hasMore() }
      next={ () => setPage(page + 1) }
      loader={ <h4>Loading...</h4> }
    >
      <Grid container spacing={2} sx={{ marginBottom: hasMore() ? 0 : "5em" }}>
        {
          leads.map<JSX.Element>((lead: LeadItem) => (
            <Grid xs={12} sm={6} md={4} lg={3} key={ `lead-${ processingStatus }-${ lead.id }` }>
              <LeadCard
                lead={ lead }
                onVisit={ () => redirectTarget(lead.url) }
                onProcessingStatusChange={ handleProcessingStatusChange(lead) }
                onNoteChange={ handleNoteChange(lead) }
                onIgnore={ ignoreLead(lead.id) }
              />
            </Grid>
          ))
        }
        {
          loading
          &&
          Array.from(new Array(4)).map<JSX.Element>((i, index: number) => (
            <Grid xs={12} sm={6} md={4} lg={3} key={ `loading-lead-${ index }` }>
              <LeadCardSkeleton />
            </Grid>
          ))
        }
      </Grid>
    </StyledInfiniteScroll>
  );
};