import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as NProgress from "nprogress";
import axios, { AxiosRequestConfig } from "axios";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import "nprogress/nprogress.css";
import { LeadListContainer } from "./lead/LeadListContainer";
import { LeadProcessingStatus } from "./lead-processing-status.enum";
import { LeadList } from "./lead/LeadList";

axios.interceptors.request.use(function (config: AxiosRequestConfig) {
  // Do something before request is sent
  if (config.url && !/^http[s]:\/\//.test(config.url)) {
    config.url = `${ process.env.REACT_APP_API_DOMAIN_N_HOST?.replace(/\/$/, "") }/${ config.url.replace(/^\//, "") }`
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

NProgress.configure({
  showSpinner: false
});

const theme = createTheme({
  palette: {
    mode: (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) ? "dark" : "light",
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <ThemeProvider theme={ theme }>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <App /> }>
            <Route path="leads" element={<LeadListContainer />}>
              <Route path=":processingStatus" element={<LeadList />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
